<template>
  <div class="mt-2" style="background-color: #FFFFFF">
    <div v-loading="loading" style="padding: 10px 20% 0 20%;">
      <el-page-header @back="goBack" content="标准技术委员会查询"></el-page-header>
      <h2 class="tac">技术委员会查询</h2>
      <el-form class="mt-4 tac" :model="searchForm" :inline="true">
        <el-form-item prop="searchText">
          <el-input v-model="searchForm.searchText" style="width: 600px" placeholder="委员会名称、业务范围" @keyup.enter.native="handleSearch" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch" type="primary" icon="el-icon-search">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tcList"  size="small" border stripe>
        <el-table-column type="index" width="50" :index="(index) =>(this.page - 1) * this.limit + index + 1" label="序号"
                         header-align="center" align="center"></el-table-column>
        <el-table-column prop="tcCode" label="委员会编号" header-align="center" align="center"
                         width="200px"></el-table-column>
        <el-table-column prop="commiteeName" label="委员会名称" header-align="center" align="center">
        </el-table-column>
        <el-table-column prop="secretariatUnit" label="秘书处所在单位" header-align="center"
                         align="center">
        </el-table-column>
        <el-table-column prop="professionalScope" label="负责专业范围" header-align="center" align="center">
          <template slot-scope="scope">
            {{ scope.row.professionalScope || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="currentTurns" label="现为第几届" header-align="center" align="center"
                         width="100px"></el-table-column>
        <el-table-column prop="action" label="操作" width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-link :href="`https://std.samr.gov.cn/search/orgDetailView?data_id=${scope.row.id}`" target="_blank" type="primary"> <i class="el-icon-info"></i><span class="f12">详情</span></el-link>
          </template>

        </el-table-column>
      </el-table>
      <div class="tar mt-1 mb-1">
<!--        <el-button @click="handleSave" type="primary" icon="el-icon-info">保存</el-button>-->
        <el-pagination :pageSize="limit" background layout="total,prev,pager,next" :total="total" @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tc-search',
  data() {
    return {
      loading: false,
      tcList: [],
      page: 1,
      limit: 20,
      total: 0,
      searchForm: {
        searchText: ''
      }
    }
  },
  created() {
    this.initTcList()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleSearch() {
      this.page = 1
      this.limit = 20
      this.initTcList()
    },
    initTcList() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.TC.LIST),
        // url: `${process.env.VUE_APP_TC_BASE_URL}?searchText=${this.searchForm.searchText}&sortOrder=asc&pageSize=${this.limit}&pageNumber=${this.page}&_=${new Date().valueOf()}`,
        // url: `https://std.samr.gov.cn/org/search/orgCommiteeInfoPage?searchText=${this.searchForm.searchText}&sortOrder=asc&pageSize=${this.limit}&pageNumber=${this.page}&_=${new Date().valueOf()}`,
        // url: `https://std.samr.gov.cn/org/search/orgCommiteeInfoPage?searchText=&sortOrder=asc&pageSize=20&pageNumber=1&_=${new Date().valueOf()}`,
        method: 'GET',
        params: this.$http.adornParams({
          searchText: this.searchForm.searchText,
          page: this.page,
          limit: this.limit
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tcList = data.page.list || []
          this.total = data.page.totalCount
        }
        // let rows = data.rows || []
        // rows.forEach(item => {
        //   item.C_COMMITEE_NAME && (item.C_COMMITEE_NAME.replace('<sacinfo>', '').replace('</sacinfo>', ''))
        //   item.C_PROFESSIONAL_SCOPE && (item.C_PROFESSIONAL_SCOPE.replace('<sacinfo>', '').replace('</sacinfo>', ''))
        // })
        // this.tcList = rows
        // this.total = data.total || 0
        this.loading = false
      })
    },
    handleSave () {
      for (let i=0; i< this.tcList.length; i ++) {
        let item = this.tcList[i]
        let tcInfo = {}
        tcInfo.id = item.id
        tcInfo.tcCode = item.TC_CODE
        tcInfo.secretariatUnit = item.SECRETARIAT_UNIT
        tcInfo.englishName = item.ENGLISH_NAME
        tcInfo.professionalScope = item.C_PROFESSIONAL_SCOPE
        tcInfo.commiteeName = item.C_COMMITEE_NAME
        tcInfo.currentTurns = item.CURRENT_TURNS
        this.doSave(tcInfo)
      }
    },
    doSave (tcInfo = {}) {
      this.$http({
        url: this.$http.adornUrl(this.$api.TC.SAVE),
        method: 'POST',
        data: this.$http.adornData({
          ...tcInfo
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            this.$message.success("保存成功！")
        }
      })
    },

    handleSizeChange(limit) {
      this.limit = limit
      this.initTcList()
    },
    handleCurrentChange(page) {
      this.page = page
      this.initTcList()
    }
  }
}
</script>

<style scoped>

</style>
